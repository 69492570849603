.gallery {

    &__header {
        text-align: center;
        font-size: 30px;
        font-family: 'Exo2-Light';
        font-weight: 300;
        margin-bottom: 45px;
    }

    &-item {
        text-align: center;
        margin-bottom: 40px;

        img {
            max-width: 100%;
            display: inline-block;
            vertical-align: top;
            max-height: 187px;
        }
    }

    .image-info {
        line-height: 24px;
        font-size: 17px;
        font-family: 'Exo2-Regular';
        color: #161616;
        margin-top: 10px;
    }

    .image-desc {
        color: #656565;
        font-size: 16px;
    }
}
