.col-center {
    float: none;
    margin: auto;
}

.contact {

    &-header {
        text-align: center;
        color: #161616;
        font-family: "Exo2-Light";
        font-weight: 300;
        font-size: 36px;
        margin-bottom: 50px;
    }

    a {
        text-decoration: none;
        color: #161616;
        font-size: 24px;
        font-family: "Exo2-Light";
    }

    &-group {
        padding-bottom: 35px;
        border-bottom: 1px solid #613213;
    }

    &-phone {
        &:before {
            content: url(../img/c-phone.png);
            display: inline-block;
            vertical-align: middle;
            margin-right: 5px;
        }
        @media only screen and (min-width: 768px ) {
            text-align: right;
            padding-right: 45px;
        }
    }

    &-mail {
        @media only screen and (min-width: 768px ) {
            padding-left: 45px;
        }
        &:before {
            content: url(../img/c-mail.png);
            display: inline-block;
            vertical-align: middle;
            margin-right: 5px;
        }
    }

    &-form {
        margin-top: 40px;
        text-align: center;
        margin-bottom: 30px;

        h3 {
            text-align: center;
            font-size: 36px;
            font-family: "Exo2-Light";
            margin-bottom: 30px;
        }

        .input {
            display: inline-block;
            max-width: 350px;
            width: 100%;
            height: 45px;
            border-radius: 3px;
            text-align: left;
            border: 1px solid #bb7242;
            padding-left: 8px;
            transition: all .2s ease;
            font-size: 18px;
            font-family: "Exo2-Light";

            &--large {
                height: 95px;
            }

            &:focus {
                border-color: #613213;
            }
        }

        .btn-submit {
            width: 160px;
            height: 40px;
            background-color: #f3b273;
            color: #fff;
            font-family: "Exo2-Regular";
            font-size: 18px;
            transition: all .2s ease;

            &:hover {
                opacity: .8;
                box-shadow: 0 10px 15px -8px rgba(0, 0, 0, .3);
            }
        }
    }
}
