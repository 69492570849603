.footer{
  .top-nav{
    padding: 7px 0;
    a{
      color: #ffdfc0;
      font-size: 16px;
      font-family: 'Exo2-Light';
      text-decoration: none;
      padding-right: 30px;
      padding-left: 24px;
      line-height: 26px;
    }
    .phone{
      position: relative;
      &:before{
        content: '';
        background: url('../img/phone.png');
        background-repeat: no-repeat;
        width: 10px;
        height: 17px;
        position: absolute;
        left: 2px;
        top: 4px;
        display: block;
      }
    }
    .mail{
      position: relative;
      &:before{
        content: '';
        background: url('../img/mail.png');
        background-repeat: no-repeat;
        width: 17px;
        height: 15px;
        position: absolute;
        left: 0;
        top: 5px;
        display: block;
      }
    }
  }
  .copiright{
    padding: 7px 0;
    p{
      color: #ffdfc0;
      font-size: 16px;
      font-family: 'Exo2-Light';
      line-height: 26px;
    }
  }
}
