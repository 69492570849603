.wrapper {
    background-color: #623517!important;
}
.header{
  background: url('../img/bg-new.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  .top-nav{
    padding-top: 7px;
    a{
      color: #ffdfc0;
      font-size: 16px;
      font-family: 'Exo2-Light';
      text-decoration: none;
      padding-right: 30px;
      padding-left: 24px;
      line-height: 26px;
    }
    .phone{
      position: relative;
      &:before{
        content: '';
        background: url('../img/phone.png');
        background-repeat: no-repeat;
        width: 10px;
        height: 17px;
        position: absolute;
        left: 2px;
        top: 4px;
        display: block;
      }
    }
    .mail{
      position: relative;
      &:before{
        content: '';
        background: url('../img/mail.png');
        background-repeat: no-repeat;
        width: 17px;
        height: 15px;
        position: absolute;
        left: 0;
        top: 5px;
        display: block;
      }
    }
  }
  .wrapper-logo{
    .name{
      h2{
        font-size: 40px;
        font-family: $TorhokItalic;
        line-height: 42px;
        color: #fff;
        display: block;
        text-align: center;
        padding: 54px 0;
      }
    }
    .logo{
    }
  }
}

@media only screen and (max-width: 767px) {
  .navbar-nav {
    margin: 7.5px 0;
  }
}

.wrapper-menu{
  background: #ffdfc0;
  border-image: url('../img/border-menu.png') 30 round round;
  padding: 5px 0px;
  position: relative;
  margin-bottom: 84px;
  &:before{
    content: '';
    background: url('../img/border-menu.png');
    width: 100%;
    height: 25px;
    background-size: cover;
    background-position: center center;
    position: absolute;
    top: -23px;
  }
  &:after{
    content: '';
    background: url('../img/border-menu.png');
    width: 100%;
    height: 25px;
    background-size: cover;
    background-position: center center;
    position: absolute;
    bottom: -23px;
  }

  .navbar-default .navbar-toggle .icon-bar {
    background-color: #613213;
  }

  .border-wrapper{
    border-top: 1px solid #623517;
    border-bottom: 1px solid #623517;
    .my-menu{
      border: none;
      .new-menuwrap{
        position: relative;
        padding: 0;
        .menu{
          float: left;
          padding-top: 0;

          @media only screen and (max-width: 767px) {
            float: none;
            width: 100%;
          }

          li {

            a {
              text-transform: uppercase;
              color: #bb7242;
              font-size: 15px;
              font-family: 'Constantine';
              text-decoration: none;
              margin-left: 0px;
              padding: 15px 42px;
              position: relative;

              &:before,
              &:after {
                content: "";
                position: absolute;
                left: 0; top: -4px;
                width: 100%;
                height: 3px;
                background-color: #613213;
                transform: scale3d(0, 0, 0);
                transition: all .3s ease;
              }

              &:after {
                top: auto;
                bottom: -4px;
              }

              &:hover:after,
              &:hover:before {
                transform: scale3d(1,1,1);
              }
            }
            &.active a {
              color: #613213;
              background: transparent;

              &:after,
              &:before {
                transform: scale3d(1,1,1);
              }
            }
          }
        }
        .logo{
          position: absolute;
          padding: 0;
          bottom: -66px;
          right: 0;
          img{
            position: relative;
            left: 45px;
            z-index: 20;
          }
        }
      }
    }
  }
  .container-fluid{
    padding: 0px;
  }
}
