@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

.clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}
