@font-face {
  font-family: 'Constantine';
  src: url('../fonts/Constantine.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Constantine.woff') format('woff'),
    url('../fonts/Constantine.ttf')  format('truetype'),
    url('../fonts/Constantine.svg#Constantine') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Conv_TorhokItalic';
  src: url('fonts/TorhokItalic.eot');
  src: local('☺'),
        url('../fonts/TorhokItalic/TorhokItalic.woff') format('woff'),
        url('../fonts/TorhokItalic/TorhokItalic.ttf') format('truetype'),
        url('../fonts/TorhokItalic/TorhokItalic.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Exo2-Regular';
  src: url('../fonts/Exo2-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Exo2-Regular.woff') format('woff'),
  url('../fonts/Exo2-Regular.ttf')  format('truetype'),
  url('../fonts/xo2-Regular.svg#Exo2-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Exo2-Light';
  src: url('../fonts/Exo2-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Exo2-Light.woff') format('woff'),
    url('../fonts/Exo2-Light.ttf')  format('truetype'),
    url('../fonts/Exo2-Light.svg#Exo2-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gabriola';
  src: url('../fonts/ufonts.com_gabriola.eot?#iefix') format('embedded-opentype'),
    url('../fonts/ufonts.com_gabriola.woff') format('woff'),
    url('../fonts/ufonts.com_gabriola.ttf')  format('truetype');
  font-weight: normal;
  font-style: normal;
}

$Constantine: "Constantine";
$TorhokItalic: "Conv_TorhokItalic";
$Exo2Regular: "Exo2-Regular";
