.wrapper-body{
  margin-bottom: 70px;
  .container-content{
    .wrapper-painter{
      padding-left: 0px;
    }
    .wrapper-info{
      border-top: 1px solid #613213;
      border-bottom: 1px solid #613213;
      margin-bottom: 40px;
      margin-top: 80px;
      p{
        padding: 41px 0;
        color: #613213;
        font-family: 'Gabriola';
        font-size: 42px;
        line-height: 47px;
        span{
          padding-top: 12px;
          font-family: 'Exo2-Light';
          font-size: 17px;
          line-height: 17px;
          color: #949494;
          text-align: center;
          display: block;
        }
      }
    }
    .privat{
      color: #2e2e2e;
      font-size: 40px;
      line-height: 40px;
      font-family: 'Gabriola';
      text-align: center;
    }
  }
}
.slick-dots li button:before{
  font-size: 36px!important;
  color: #613213!important;
}
.wrapper-slider{
  background: #ffe0c1;

}
.bg-wrapper{
  background: url('../img/bg-new.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 45px;
  padding-bottom: 40px;
}

.painter-photo  { text-align: center; }

.slick-slider {
  margin: 0 -13px;
}

.slider-works .slick-dots {
  bottom: -40px;
  li { margin: 0 }
}

.items {
  position: relative;
  margin: 0 13px;

  img { margin: auto; }

  &-inner {
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(78, 45, 23, 0.7);
    visibility: hidden;
    opacity: 0;
    transition: all .4s ease;
    overflow: hidden;
  }

  .inner {

      &-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        color: white;
        text-align: center;
      }

      &-txt {
        font-family: "Exo2-Regular";
        font-size: 22px;
        font-weight: 500;
        line-height: 28px;
        padding: 0 20px;
        transform: translateY(-15px);
        opacity: 0;
        transition: all .3s ease;
        will-change: transform;

        @media only screen and (max-width: 767px) {
          font-size: 18px;
          line-height: 20px;
        }
      }

      &-btn {
        display: inline-block;
        width: 160px;
        height: 45px;
        background-color: white;
        border-radius: 6px;
        color: #613213;
        text-decoration: none;
        font-family: "Exo2-Regular";
        font-size: 17px;
        font-weight: 500;
        line-height: 45px;
        margin-top: 20px;
        transform: translateY(15px);
        opacity: 0;
        transition: all .3s ease;
        will-change: transform;

        @media only screen and (max-width: 767px) {
            width: 120px;
            height: 30px;
            line-height: 28px;
        }

        &:hover {
          box-shadow: 0 0 0 1px rgba(0, 0, 0, .4),
                      inset 0 0 0 2px rgba(255, 255, 255, .08),
                      0 0 35px rgba(255, 255, 255, .4);
          background-color: #613213;
          color: #fff;
        }
      }
  }

  &:hover {
    .items-inner {
      visibility: visible;
      opacity: 1;
    }
    .inner-txt,
    .inner-btn {
      opacity: 1;
      transform: translateY(0);
    }
  }

}
