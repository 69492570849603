.biography {
    font-family: "Exo2-Light";
    margin-bottom: 60px;

    &-inner {
        margin-top: 30px;
    }

    @media only screen and (max-width: 767px) {
        .picture {
            float: none !important;
            display: block;
            margin: 30px auto !important;
        }
    }

    h2 {
        color: #161616;
        font-size: 36px;
        font-weight: 100;
        line-height: 26px;
        text-align: center;
        margin-bottom: 34px;
    }

    .sub-header {
        text-align: left;
        margin: 17px 0;
    }

    .pull-left {
        margin-right: 30px;
        // margin-bottom: 20px;
    }

    .pull-right {
        margin-left: 30px;
        margin-bottom: 30px;
    }

    p {
        font-weight: 300;
        line-height: 24px;
        font-size: 18px;

        &:not(:first-child) {
            margin-top: 20px;
        }
    }
}
